import React from "react"
import { graphql } from "gatsby"
import Seo from "../../components/seo"
import Layout from "../../app/layout"
import { Link, useI18next } from "gatsby-plugin-react-i18next"

import { Row, Col, Breadcrumb } from "antd"

import { ChevronRight } from "../../components/Icon"

import Container from "../../components/Container"
import Masthead from "../../components/Masthead"
import Blockquote from "../../components/Blockquote"

import VideoBackground from "../../images/video-bg.jpg"
import ScrollAnimation from "react-animate-on-scroll"

import "./tentang.less"

const SelayangPandangPage = ({ data: { aboutSelayangPandang: data = {} } }) => {
  const { t } = useI18next()
  return (
    <Layout module_name="about" page_name="selayang-pandang">
      <Seo
        title={data.header_title}
        module_name="about"
        page_name="selayang-pandang"
      />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <Link to="/">{t("Beranda")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.header_title}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Masthead
        image={VideoBackground}
        title={data.header_title}
        useVideo={true}
        videoId="9_23rNgPrp0"
        textBottom={t("Lihat Sejarah Kami")}
        playBtnText={t("Putar Video")}
      />

      <div className="section section-secondary text-medium">
        <Container>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2 className="section-title-huge">{data.history_title}</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={2}>
            <Row gutter={40} justify="space-between" style={{ margin: 0 }}>
              <Col span={24} md={12} style={{ padding: 0 }}>
                <div
                  dangerouslySetInnerHTML={{ __html: data.history_content }}
                />
              </Col>
              <Col span={24} md={10} style={{ padding: 0 }}>
                <Blockquote type="secondary" content={data.history_quote} />
              </Col>
            </Row>
          </ScrollAnimation>

          <Row gutter={30}>
            <Col span={24} md={12}>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOnce={true}
                delay={1}
              >
                <img
                  src={data.history_image_1}
                  alt={data.history_short_description_1}
                  width="100%"
                />
                <p style={{ marginTop: 12 }}>
                  {data.history_short_description_1}
                </p>
                <hr />
              </ScrollAnimation>
            </Col>

            <Col span={24} md={12}>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOnce={true}
                delay={5}
              >
                <img
                  src={data.history_image_2}
                  alt={data.history_short_description_2}
                  width="100%"
                />
                <p style={{ marginTop: 12 }}>
                  {data.history_short_description_2}
                </p>
                <hr />
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="section text-medium">
        <Container>
          <Row gutter={40} align="middle" style={{ margin: 0 }}>
            <Col span={24} md={12} style={{ padding: 0 }} />
            <Col span={24} md={12} style={{ padding: 0 }}>
              <div
                className="show-mobile text-center"
                style={{ padding: "100px 0" }}
              >
                <img
                  width="176"
                  src={data.logo_image}
                  alt="LPDP"
                  style={{ marginBottom: 20 }}
                />
              </div>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <h2 className="section-title-huge">{data.logo_title}</h2>
              </ScrollAnimation>
            </Col>
            <Col
              span={24}
              md={12}
              className="text-center"
              style={{ padding: 0 }}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                animateOnce={true}
                delay={2}
              >
                <img
                  className="hide-mobile"
                  src={data.logo_image}
                  alt="LPDP"
                  width="200"
                  style={{ marginBottom: 20 }}
                />
              </ScrollAnimation>
            </Col>
            <Col span={24} md={12} style={{ padding: 0 }}>
              <ScrollAnimation
                animateIn="fadeInRight"
                animateOnce={true}
                delay={2}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: data.logo_description }}
                />
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="section text-medium">
        <Container>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <div className="text-center">
              <h2 className="section-title-huge">{t("Nilai dan Budaya")}</h2>
            </div>
          </ScrollAnimation>

          <div className="nilai-budaya">
            {/* Start Nilai Budaya 1 */}
            <ScrollAnimation
              className="nilai-budaya-item"
              animateIn="fadeInUp"
              animateOnce={true}
            >
              <div className="nilai-budaya-content">
                <h3>{data.culture_value_title_1}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.culture_value_description_1,
                  }}
                />
              </div>
              <span className="nilai-budaya-letter">
                {data.culture_value_title_1.substring(0, 1)}
              </span>
            </ScrollAnimation>
            <div className="nilai-budaya-item null"></div>
            {/* End Nilai Budaya 1 */}

            {/* Start Nilai Budaya 2 */}
            <ScrollAnimation
              className="nilai-budaya-item"
              animateIn="fadeInUp"
              animateOnce={true}
              delay={100}
            >
              <div className="nilai-budaya-content">
                <h3>{data.culture_value_title_2}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.culture_value_description_2,
                  }}
                />
              </div>
              <span className="nilai-budaya-letter">
                {data.culture_value_title_2.substring(0, 1)}
              </span>
            </ScrollAnimation>
            <div className="nilai-budaya-item null"></div>
            {/* End Nilai Budaya 2 */}

            {/* Start Nilai Budaya 3 */}
            <ScrollAnimation
              className="nilai-budaya-item"
              animateIn="fadeInUp"
              animateOnce={true}
              delay={200}
            >
              <div className="nilai-budaya-content">
                <h3>{data.culture_value_title_3}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.culture_value_description_3,
                  }}
                />
              </div>
              <span className="nilai-budaya-letter">
                {data.culture_value_title_3.substring(0, 1)}
              </span>
            </ScrollAnimation>
            <div className="nilai-budaya-item null"></div>
            {/* End Nilai Budaya 3 */}

            {/* Start Nilai Budaya 4 */}
            <ScrollAnimation
              className="nilai-budaya-item"
              animateIn="fadeInUp"
              animateOnce={true}
              delay={300}
            >
              <div className="nilai-budaya-content">
                <h3>{data.culture_value_title_4}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.culture_value_description_4,
                  }}
                />
              </div>
              <span className="nilai-budaya-letter">
                {data.culture_value_title_4.substring(0, 1)}
              </span>
            </ScrollAnimation>
            <div className="nilai-budaya-item null"></div>
            {/* End Nilai Budaya 4 */}

            {/* Start Nilai Budaya 5 */}
            <ScrollAnimation
              className="nilai-budaya-item"
              animateIn="fadeInUp"
              animateOnce={true}
              delay={400}
            >
              <div className="nilai-budaya-content">
                <h3>{data.culture_value_title_5}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.culture_value_description_5,
                  }}
                />
              </div>
              <span className="nilai-budaya-letter">
                {data.culture_value_title_5.substring(0, 1)}
              </span>
            </ScrollAnimation>
            <div className="nilai-budaya-item null"></div>
            {/* End Nilai Budaya 1 */}
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default SelayangPandangPage

export const query = graphql`
  query ($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    aboutSelayangPandang(lang: { eq: $language }) {
      culture_value_description_1
      culture_value_description_2
      culture_value_description_3
      culture_value_description_4
      culture_value_description_5
      culture_value_title_1
      culture_value_title_2
      culture_value_title_3
      culture_value_title_4
      culture_value_title_5
      header_image
      header_title
      header_use_video
      header_video_url
      history_content
      history_image_1
      history_image_2
      history_quote
      history_short_description_1
      history_short_description_2
      history_title
      logo_description
      logo_image
      logo_title
      slug
    }
  }
`
