import React from "react"
import PropTypes from "prop-types"
import { PlayCircleFilled } from "@ant-design/icons"
import { Modal, Button } from "antd"
import YouTube from "react-youtube"
import ScrollAnimation from "react-animate-on-scroll"

import "./Masthead.less"

class Masthead extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
    // create a ref to store the textInput DOM element
    this.videoIframe = React.createRef()
  }

  showModal = () => {
    this.setState({ visible: true })
  }

  handleCancel = () => {
    this.setState({ visible: false })
    this.videoIframe.current.internalPlayer.pauseVideo()
  }

  render() {
    const { visible } = this.state

    const opts = {
      height: "390",
      width: "100%",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        rel: 0,
        modestbranding: 0,
        showinfo: 0,
      },
    }

    return (
      <div className="masthead">
        <div className="masthead-content">
          <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
            <h1>{this.props.title}</h1>
          </ScrollAnimation>

          <div className="meta">
            <div dangerouslySetInnerHTML={{ __html: this.props.textBottom }} />
            <div className="scroll-downs">
              <div className="mousey">
                <div className="scroller"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="masthead-media">
          <div
            className="masthead-image"
            style={{ backgroundImage: `url(${this.props.image})` }}
          >
            {this.props.useVideo === true && (
              <>
                <Button
                  onClick={this.showModal}
                  icon={<PlayCircleFilled />}
                  className="btn-outline-white"
                  size="large"
                >
                  {this.props.playBtnText
                    ? this.props.playBtnText
                    : "Putar Video"}
                </Button>

                <Modal
                  width="60%"
                  className="video-modal"
                  visible={visible}
                  onCancel={this.handleCancel}
                  footer={false}
                >
                  <YouTube
                    ref={this.videoIframe}
                    videoId={this.props.videoId}
                    opts={opts}
                  />
                </Modal>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

Masthead.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  useVideo: PropTypes.bool,
  videoId: PropTypes.string,
  playBtnText: PropTypes.string,
  textBottom: PropTypes.string,
}

Masthead.defaultProps = {
  useVideo: false,
}

export default Masthead
