import React from "react"
import PropTypes from "prop-types"

import "./Blockquote.less"

const Blockquote = ({ name, content, type }) => (
  <div className={`blockquote ${type}`}>
    <svg
      className="first"
      width="62"
      height="48"
      viewBox="0 0 62 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M60.5589 4.10102C60.5589 6.02156 59.1871 7.73632 57.2665 8.07927C54.3857 8.628 51.5735 9.86263 48.8299 11.8517C45.0574 14.5268 43.1369 17.8191 43.1369 21.7288C43.1369 25.2955 46.4978 25.1583 49.1729 24.8839C58.6383 23.8551 64.9487 34.0065 59.9416 42.1001C55.1402 49.9194 43.0683 49.5765 37.5811 42.8546C29.6932 33.1834 32.4368 15.6242 41.2164 7.39337C45.4004 3.48372 50.2703 1.08305 55.8947 0.0541878C58.2954 -0.357353 60.5589 1.63177 60.5589 4.10102ZM27.6355 4.10102C27.6355 6.02156 26.2636 7.73632 24.3431 8.07927C21.4623 8.628 18.6501 9.86263 15.9065 11.8517C12.134 14.5268 10.2135 17.8191 10.2135 21.7288C10.2135 25.2955 13.5744 25.1583 16.2494 24.8839C25.7149 23.8551 32.0252 34.0065 27.0181 42.1687C22.2168 49.988 10.1449 49.6451 4.65766 42.9232C-3.23024 33.252 -0.486629 15.6928 8.29295 7.46196C12.477 3.5523 17.3469 1.15163 22.9713 0.12278C25.372 -0.357353 27.6355 1.63177 27.6355 4.10102Z"
        fill="#572D2D"
      />
    </svg>

    <div className="blockquote-inner">
      <blockquote>
        <div
          style={{ display: "inline" }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {type === "secondary" && (
          <svg
            className="last"
            width="62"
            height="48"
            viewBox="0 0 62 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M60.5589 4.10102C60.5589 6.02156 59.1871 7.73632 57.2665 8.07927C54.3857 8.628 51.5735 9.86263 48.8299 11.8517C45.0574 14.5268 43.1369 17.8191 43.1369 21.7288C43.1369 25.2955 46.4978 25.1583 49.1729 24.8839C58.6383 23.8551 64.9487 34.0065 59.9416 42.1001C55.1402 49.9194 43.0683 49.5765 37.5811 42.8546C29.6932 33.1834 32.4368 15.6242 41.2164 7.39337C45.4004 3.48372 50.2703 1.08305 55.8947 0.0541878C58.2954 -0.357353 60.5589 1.63177 60.5589 4.10102ZM27.6355 4.10102C27.6355 6.02156 26.2636 7.73632 24.3431 8.07927C21.4623 8.628 18.6501 9.86263 15.9065 11.8517C12.134 14.5268 10.2135 17.8191 10.2135 21.7288C10.2135 25.2955 13.5744 25.1583 16.2494 24.8839C25.7149 23.8551 32.0252 34.0065 27.0181 42.1687C22.2168 49.988 10.1449 49.6451 4.65766 42.9232C-3.23024 33.252 -0.486629 15.6928 8.29295 7.46196C12.477 3.5523 17.3469 1.15163 22.9713 0.12278C25.372 -0.357353 27.6355 1.63177 27.6355 4.10102Z"
              fill="#572D2D"
            />
          </svg>
        )}
      </blockquote>
      {name && <span className="blockquote-footer">— {name}</span>}
    </div>
  </div>
)

Blockquote.propTypes = {
  content: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.PropTypes.oneOf(["primary", "secondary"]),
}

Blockquote.defaultProps = {
  type: "primary",
}

export default Blockquote
